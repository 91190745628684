.ConfigContent {
  height: 100%;
}

.table-title {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F8F8F8;
  border: 1px solid #dee2e6 !important;
  border-bottom: 0px;
  align-items: center;
}

.table-title input {
  height: 30px;
  margin-left: 8px;
}

.table-title label {
  margin: 0px;
  margin-right: 5px;
  margin-left: 10px;
  align-self: center;
  font-weight: bold;
}

.table-title button {
  width: 15em;
}
.table-container {
  height: 60vh;
}

.caret {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000000;
  width:1px;
}

select {
  height: 30px;
  min-width: 250px;
  margin-right: 20px;
  margin-left: 8px;
}

input {
  margin-right: 20px;
  margin-left: 8px;
}

.table-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.table-filters button {
  width: 10em;
}

.table-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F8F8F8;
  border: 1px solid #dee2e6 !important;
  border-top: 0px;
  padding: 5px;
}

.info {
  display: flex;
  flex-direction: row;
}

.info p {
  margin: 0px;
  margin-right: 10px;
  align-self: center;
}

.table-bottom button {
  width: 15em;
}

.multiselect-checkbox{
display: flex;
flex-direction: column;
}

.checkbox{
text-align: start;
}

.multiSelect-box{
  border: 0.5px solid black;
  width: 250px;
  vertical-align: bottom;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:4px;
  margin-right: 20px;
  height: 30px;
}

.display-block{
  display: block;
  position: absolute;
  background-color: #fff;
  border: 0.5px solid black;
  padding:8px;
  width: 250px;
  height:30vh;
  overflow-y: auto;
}

.display-none{
  display: none;
}