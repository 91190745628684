.exception {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 8px;
    border: 1px solid #dee2e6 !important;
    background-color: #f8f8f8;
    align-items: center;
}